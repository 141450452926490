import {
  Box,
  Button,
  Card,
  CircularProgress,
  Input,
  Textarea,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import handleContact from "../../handlers/ses-handler";

// const abos = [
//   {
//     name: "Anfänger",
//     price: 2.99,
//     description:
//       "Für Hobbyflieger und Anfänger, die sich mit der Software vertraut machen wollen oder sich mit weniger Daten zufrieden geben.",
//     included: [
//       "10 Flüge",
//       "Genaustes Tracking",
//       "Statistiken",
//       "Max. 2 Gleiter",
//     ],
//     excluded: ["Live Wetterdaten", "Fluganalyse", "Schülerverwaltung"],
//   },
//   {
//     name: "Profi",
//     price: 5.99,
//     description:
//       "Für alle, die auf unsere erweiterte Fluganalyse und unsere live Wetterdaten nicht verzichten wollen.",
//     included: [
//       "Unbegrenzte Flüge",
//       "Genaustes Tracking",
//       "Statistiken",
//       "Max. 10 Gleiter",
//       "Live Wetterdaten",
//       "Fluganalyse",
//     ],
//     excluded: ["Schülerverwaltung"],
//   },
//   {
//     name: "Flugschule",
//     noprice: true,
//     price: "Preis auf Anfrage",
//     description:
//       "Für Flugschulen, die ihre Schüler mit unserem Tracking und der Software unterstützen wollen.",
//     included: [
//       "Unbegrenzte Flüge",
//       "Genaustes Tracking",
//       "Statistiken",
//       "Unbegrenzte Gleiter",
//       "Live Wetterdaten",
//       "Fluganalyse",
//       "Schülerverwaltung",
//     ],
//   },
// ];

const Pricing = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [infoText, setInfoText] = useState("");

  const onSubmit = (e) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    handleContact(data)
      .then(() => {
        setIsSubmitting(false);
        setInfoText("Vielen Dank für Ihre Anfrage!");
        e.target.reset();
        setTimeout(() => {
          setInfoText("");
        }, 5000);
      })
      .catch(() => {
        setIsSubmitting(false);
        setInfoText(
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        );
        setTimeout(() => {
          setInfoText("");
        }, 5000);
      });
  };

  return (
    <Box
      className="w-full mx-auto flex flex-col"
      sx={{
        maxWidth: "var(--content-width)",
        transform: "translateY(30%)",
        mt: -58,
        pt: 5,
      }}
      id="pricing"
    >
      <Typography level="h4" textAlign={"center"} sx={{ color: "white" }}>
        Flexible Preismodelle basierend auf Ihren
      </Typography>
      <Typography
        level="h4"
        textAlign={"center"}
        sx={{ color: "var(--primary-color)" }}
      >
        Erfahrungen und Bedürfnissen
      </Typography>
      <Typography
        textAlign={"center"}
        level="body1"
        sx={{ mt: 3, color: "#E6E8EC" }}
      >
        Kontaktieren Sie uns für ein maßgeschneidertes Angebot noch heute!
      </Typography>
      <Box
        className="flex justify-center mt-8 p-4"
        sx={{ "--swiper-navigation-size": { xs: "2rem", md: "3rem" } }}
      >
        <Card
          className="w-full flex justify-center items-center gap-8"
          sx={{
            backgroundColor: "white",
            // paddingTop: { xs: 35, md: 48 },
          }}
          id="contact"
        >
          <Box
            className="flex flex-col w-full py-6 px-6"
            sx={{ maxWidth: "var(--content-width)" }}
          >
            <Box className="flex flex-grow gap-4 mx-auto flex-wrap justify-between w-full mt-8">
              {/* <Box className="flex flex-col gap-2" sx={{ flexGrow: 2 }}>
                <Typography
                  textColor="var(--primary-color)"
                  level="h5"
                  fontWeight={600}
                >
                  Kontakieren Sie uns
                </Typography>
                <Typography textColor="#111821" level="h4" fontWeight={600}>
                  Gerne erstellen wir Ihnen ein unverbindliches Angebot
                </Typography>
              </Box> */}
              <Box className="flex flex-grow flex-wrap">
                <Box className="flex flex-grow gap-4">
                  <MdLocationOn size={25} color={"var(--primary-color)"} />
                  <Box className="flex flex-col">
                    <Typography textColor="#747F94" level={"body1"}>
                      Gasteigerstraße 21c
                    </Typography>
                    <Typography textColor="#747F94" level={"body1"}>
                      6380 St. Johann i. T.
                    </Typography>
                    <Typography textColor="#747F94" level={"body1"}>
                      Österreich
                    </Typography>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-4">
                  <Box className="flex gap-4 items-center">
                    <BsFillTelephoneFill
                      size={25}
                      color={"var(--primary-color)"}
                    />
                    <Typography
                      textColor="#747F94"
                      level={"body1"}
                      component="a"
                      href="tel:+436605767679"
                    >
                      +43 660 576 767 9
                    </Typography>
                  </Box>
                  <Box className="flex gap-4 items-center">
                    <IoMail size={25} color={"var(--primary-color)"} />
                    <Typography
                      textColor="#747F94"
                      level={"body1"}
                      component="a"
                      href="mailto:office@bhn-soft.at"
                    >
                      office@bhn-soft.at
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <form className="flex flex-col gap-8 mt-16" onSubmit={onSubmit}>
              <Box className="grid grid-cols-3 gap-8">
                <Input
                  sx={{
                    gridColumn: { xs: "span 3", md: "span 1" },
                    backgroundColor: "#F2F3F7",
                    borderRadius: 8,
                  }}
                  color="neutral"
                  placeholder="Max Mustermann *"
                  size="lg"
                  required
                  variant="plain"
                  name="name"
                />
                <Input
                  sx={{
                    gridColumn: { xs: "span 3", md: "span 1" },
                    backgroundColor: "#F2F3F7",
                    borderRadius: 8,
                  }}
                  color="neutral"
                  placeholder="Deine E-Mail *"
                  size="lg"
                  required
                  variant="plain"
                  type="email"
                  name="email"
                />
                <Input
                  sx={{
                    gridColumn: { xs: "span 3", md: "span 1" },
                    backgroundColor: "#F2F3F7",
                    borderRadius: 8,
                  }}
                  color="neutral"
                  type="tel"
                  placeholder="Handynummer Bsp. +XX XXX XXXXXXX *"
                  size="lg"
                  required
                  variant="plain"
                  name="phone"
                />
              </Box>
              <Box>
                <Textarea
                  sx={{ backgroundColor: "#F2F3F7" }}
                  minRows={10}
                  maxRows={10}
                  color="neutral"
                  placeholder="Erzähl uns etwas über dein Unternehmen... *"
                  size="lg"
                  variant="plain"
                  required
                  name="message"
                />
              </Box>
              <Box className="flex justify-between">
                <div>
                  <Typography
                    level="body1"
                    sx={{ color: "var(--primary-color)" }}
                  >
                    {infoText}
                  </Typography>
                </div>
                <Button
                  type="submit"
                  className="button--round"
                  sx={{
                    fontWeight: "500 !important",
                    color: "white !important",
                    backgroundColor: "var(--primary-color) !important",
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Box className="relative top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                      <CircularProgress color="primary" />
                    </Box>
                  ) : (
                    "Absenden"
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
      <Box
        className="absolute hidden xl:block"
        sx={{
          bottom: 0,
          right: 0,
          transform: "translate(40%, 10%)",
        }}
      >
        <img src="/Vector2.svg" alt="dots" role="presentation" />
      </Box>
    </Box>
  );
};

export default Pricing;
