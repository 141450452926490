import { Box, Button, Typography } from "@mui/joy";
import Tools from "../modals/Tools";
import { useState } from "react";

const AboutOurCompany = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      className="mx-auto flex justify-center items-center gap-8 py-8 flex-wrap md:flex-nowrap"
      sx={{
        maxWidth: "var(--content-width)",
        marginBottom: "28rem",
        marginTop: "4rem",
        px: "2rem",
      }}
      id="functions"
    >
      <Box className="flex flex-col gap-3 w-full md:w-1/2 order-1">
        <Typography
          level="h6"
          sx={{
            fontWeight: "500",
            color: "var(--primary-color)",
            letterSpacing: "2px",
          }}
        >
          IHRE REISE BEGINNT HIER!
        </Typography>
        <Typography level="h4" fontWeight="600" lineHeight={2}>
          Effiziente{" "}
          <span style={{ color: "var(--primary-color)" }}>Flugbuchung</span> mit{" "}
          <span style={{ color: "var(--primary-color)" }}>ParaBook</span>
        </Typography>
        <Typography level="body1" fontWeight={400}>
          Mit ParaBook können Sie Ihre Flüge schnell und mühelos buchen. Schluss
          mit unnötigen Telefonaten und Missverständnissen – unsere intuitive
          Plattform macht die Buchung zum Kinderspiel. Wir bieten Ihnen die
          Möglichkeit, unser Buchungssystem nahtlos in Ihre Unternehmenswebsite
          zu integrieren. Dadurch erhalten Ihre Kunden eine reibungslose
          Buchungserfahrung, die sie lieben werden. Unsere Software generiert
          automatisch Flugzeiten, die auf den Öffnungs- und Schließzeiten der
          Bergbahnen basieren. So können Sie sich zu 100% dem Fliegen widmen,
          ohne sich um lästige Planungsdetails kümmern zu müssen.
        </Typography>
        <Box className="flex mt-2">
          <Button
            onClick={handleOpen}
            className="button--round"
            sx={{
              fontWeight: "500 !important",
              color: "white !important",
              backgroundColor: "var(--primary-color) !important",
            }}
          >
            Mehr Erfahren
          </Button>
          <Tools open={open} handleClose={handleClose} />
        </Box>
      </Box>
      <Box className="w-full md:w-1/2 md:order-1 flex justify-center">
        <img src="/glider-1.png" alt="glider" />
      </Box>
    </Box>
  );
};

export default AboutOurCompany;
