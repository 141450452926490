export default async function handleContact(data) {
  const url = "https://63ciejz9v8.execute-api.eu-central-1.amazonaws.com/send";
  data.source = "ParaBook";

  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}
