import { Box, Button, Card, Typography } from "@mui/joy";

const NextLevelFlightSchool = () => {
  return (
    <Card
      className="mx-auto p-4"
      sx={{
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "var(--content-width)",
        transform: { xs: "translateY(-30%)", md: "translateY(-50%)" },
      }}
      id="flightschools"
    >
      <Box className="w-full flex justify-center items-center gap-8 py-12 px-6 relative flex-wrap md:flex-nowrap">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: "translate(-65%, -50%)",
          }}
          className="hidden xl:block"
        >
          <img src="/Vector1.svg" alt="dots" role={"presentation"} />
        </Box>
        <Box className="w-full md:w-1/2 flex justify-center">
          <img src="/Frame.png" alt="glider" />
        </Box>
        <Box className="flex flex-col gap-3 w-full md:w-1/2">
          <Typography
            level="h6"
            sx={{
              fontWeight: "500",
              color: "var(--primary-color)",
              letterSpacing: "2px",
            }}
          >
            ALLES UNTER KONTROLLE!
          </Typography>
          <Typography level="h4" fontWeight="600" lineHeight={2}>
            Optimierung durch{" "}
            <span style={{ color: "var(--primary-color)" }}>ParaBook</span>
          </Typography>
          <Typography level="body1" fontWeight={400} lineHeight={1.8}>
            Mit ParaBook können Ihre Piloten ganz einfach ihre Verfügbarkeiten
            eintragen. So stellen Sie sicher, dass immer nur verfügbare Piloten
            gebucht werden und Ihre Flüge reibungslos ablaufen. Mit unserem
            exklusiven Flugstatus-Tool behalten Sie stets den Überblick über
            alle Flüge Ihrer Piloten. Sie sehen sofort, welche Flüge bestätigt,
            abgelehnt oder noch nicht gelesen wurden. Nutzen Sie unser
            leistungsstarkes Statistik-Tool, um Flüge nach verschiedenen
            Kriterien zu sortieren und wertvolle Einblicke zu gewinnen. Finden
            Sie heraus, welche Flugrouten, Piloten oder Zeiten am
            erfolgreichsten sind.
          </Typography>
          <Box className="flex mt-2">
            <Button
              onClick={() => {
                const element = document.querySelector("#pricing");
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="button--round"
              sx={{
                fontWeight: "500 !important",
                color: "white !important",
                backgroundColor: "var(--primary-color) !important",
              }}
              component="a"
              href="#pricing"
            >
              Jetzt Kontakieren
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default NextLevelFlightSchool;
