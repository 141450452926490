import { Box, Modal, Typography } from "@mui/material";
import { RxCross1 } from "react-icons/rx";

export default function Tools({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        className=" bg-white absolute p-5"
        sx={{
          borderRadius: "16px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "96vw", sm: "85vw", md: "65vw" },
          maxHeight: { xs: "100vh", md: "80vh" },
        }}
      >
        <div className="flex flex-row items-center justify-between">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Funktionen von ParaBook
          </Typography>
          <button
            onClick={handleClose}
            style={{ border: "none", background: "none" }}
          >
            <RxCross1 size={25} />
          </button>
        </div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          1. Einfache Flugbuchung: ParaBook ermöglicht es, Flüge schnell und
          mühelos zu buchen. Sobald eine Buchung getätigt wird, erhalten die
          beteiligten Piloten sofort eine Benachrichtigung auf ihr Handy. So
          vermeiden sie unnötige Telefonate und Unklarheiten.
          <br />
          <br /> 2. Integrierbar in Ihre Website: Wir bieten die Möglichkeit,
          unser Buchungssystem nahtlos in die Website Ihres Unternehmens zu
          integrieren, um Ihren Kunden eine reibungslose Buchungserfahrung zu
          bieten. <br />
          <br />
          3. Automatisierte Zeitplanung: Unsere Software generiert automatisch
          Zeiten basierend auf den Öffnungs- und Schließzeiten der jeweiligen
          Bergbahnen. Dies ermöglicht eine schnelle Buchung, um sich wieder zu
          100% dem fliegen widmen zu können.
          <br />
          <br /> 4. Pilotenverwaltung: Ihre Piloten können ihre Abwesenheiten
          eintragen, um sicherzustellen, dass nur verfügbare Piloten gebucht
          werden. <br />
          <br />
          5. Flugstatus-Tool: Mit unserem exklusiven Flugstatus-Tool behalten
          Sie den Überblick über alle Flüge Ihrer Piloten, einschließlich
          bestätigter, abgelehnter und noch nicht gelesener Flüge.
          <br />
          <br /> 6. Statistik-Tool: Unser Statistik-Tool ermöglicht es Ihnen,
          Flüge nach Flugort, Pilot und vielen anderen Kriterien zu sortieren,
          um wertvolle Einblicke zu gewinnen.
        </Typography>
      </Box>
    </Modal>
  );
}
