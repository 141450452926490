import { Box, Button } from "@mui/joy";
import { useState } from "react";
import Tools from "../modals/Tools";

import { AiOutlineFileUnknown, AiOutlineUser } from "react-icons/ai";

const Thumbnail = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      className="w-full flex flex-col items-center justify-center"
      sx={{
        backgroundImage: "url('/hero.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        marginTop: "-8rem",
        height: "45.6rem",
        backgroundPosition: "center",
      }}
    >
      <Box
        className="container mt-15 text-center"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="w-full flex justify-center">
          {/* <LogoSlogan className="w-3/5" /> */}
          <img
            src="/slogan.png"
            alt="slogan"
            style={{
              maxHeight: "15rem",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: "1rem",
            mt: { xs: "1rem", md: "0" },
          }}
        >
          <Button className="button--round" onClick={handleOpen}>
            <AiOutlineFileUnknown color="#F39200" />
            Mehr Erfahren
          </Button>
          <Button
            className="button--round"
            sx={{
              color: "white !important",
              backgroundColor: "#F39200 !important",
            }}
            component="a"
            href="#contact"
          >
            <AiOutlineUser color="white" />
            Kontaktieren Sie uns
          </Button>
          <Tools open={open} handleClose={handleClose} />
        </Box>
      </Box>
    </Box>
  );
};

export default Thumbnail;
