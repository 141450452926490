import { Box, Button, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

import {
  // FaFacebookF,
  // FaInstagram,
  // FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const anchors = [
  { href: "https://bhn-soft.at/legal", name: "Impressum" },
  { href: "https://bhn-soft.at/privacy", name: "Datenschutz" },
  // { href: "/terms", name: "AGBs" },
];

const socials = [
  // { href: "/facebook", name: "Facebook", icon: <FaFacebookF /> },
  // { href: "/instagram", name: "Instagram", icon: <FaInstagram /> },
  // { href: "/twitter", name: "Twitter", icon: <FaTwitter /> },
  {
    href: "https://linkedin.com/company/bhnsoft",
    name: "LinkedIn",
    icon: <FaLinkedin />,
  },
];

// const MenuLink = ({ name, href }) => {
//   const goToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };
//   return (
//     <Link to={href} className="flex items-center">
//       <Button
//         variant="plain"
//         className="flex items-center justify-center"
//         sx={{
//           color: "white",
//           my: { xs: 0.5, md: 2 },
//           fontSize: "1rem",
//           ":hover": {
//             backgroundColor: "unset",
//             textDecoration: "underline",
//           },
//         }}
//         onClick={goToTop}
//       >
//         <span>{name}</span>
//       </Button>
//     </Link>
//   );
// };

const Footer = () => {
  return (
    <footer
      className="w-full justify-center"
      style={{
        backgroundColor: "var(--bg-color)",
        backgroundImage: "url(/mountains.png)",
        backgroundBlendMode: "soft-light",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        className="mx-auto flex flex-col p-3 pt-5 mt-10"
        sx={{
          maxWidth: "var(--content-width)",
        }}
      >
        <Box className="grid md:grid-cols-3 xs:grid-cols-1 gap-4">
          <Link to="/" className="flex justify-center sm:justify-start">
            <img
              src="/favicon_logo.png"
              alt="logo"
              style={{
                maxHeight: "5rem",
              }}
            />
          </Link>
          <nav className="flex justify-center flex-wrap gap-3 items-center">
            {anchors.map((anchor, i) => (
              <a href={anchor.href} key={i}>
                <Typography
                  level="body1"
                  sx={{
                    color: "white",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {anchor.name}
                </Typography>
              </a>
            ))}
          </nav>
          <Box
            className="flex gap-2 items-center"
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            {socials.map((social) => (
              <a
                href={social.href}
                key={social.name}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  sx={{
                    backgroundColor: "var(--primary-color)",
                    borderRadius: "50%",
                    height: "3rem",
                    width: "3rem",
                    transition: "all 0.2s !important",
                    ":hover": {
                      backgroundColor: "white",
                      color: "var(--primary-color)",
                      transform: "scale(1.2)",
                    },
                  }}
                >
                  {social.icon}
                </Button>
              </a>
            ))}
          </Box>
        </Box>
        <Box className="grid xs:grid-cols-1 md:grid-cols-3 mt-8 mb-8 gap-4">
          <Box className="mx-auto md:ml-0">
            <Box className="flex items-center gap-5">
              <img src="/Map.png" alt="map_icon" />
              <Box className="flex flex-col">
                <Typography textColor="white" level="body1">
                  Gasteigerstraße 21c
                </Typography>
                <Typography textColor="white" level="body1">
                  6380 St. Johann i. T.
                </Typography>
                <Typography textColor="white" level="body1">
                  Österreich
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="flex md:mx-auto gap-5 items-center justify-center">
            <img src="/Mail.png" alt="map_icon" />
            <Typography level="body1" sx={{ color: "white" }}>
              <a className="hover:underline" href="mailto:office@bhn-soft.at">
                office@bhn-soft.at
              </a>
            </Typography>
          </Box>
          <Box className="md:ml-auto flex gap-5 items-center justify-center">
            <img src="/Phone.png" alt="map_icon" />
            <Typography level="body1" sx={{ color: "white" }}>
              <a className="hover:underline" href="tel:+436605767679">
                +43 660 576 767 9
              </a>
            </Typography>
          </Box>
        </Box>
        <Box className="w-full flex items-center justify-center mt-5 mb-4">
          <Typography
            level="body2"
            textColor="white"
            marginBottom="1rem"
            fontSize={12}
          >
            © 2023 BHNsoft GesbR - All rights reserved
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
